@charset "UTF-8";
.font_b01 {
  font-weight: bold;
  padding: 0 0 5px 0;
}

.font_c01 {
  color: #5298f2;
}

.p_u5 {
  padding: 5px 0 0 0;
}

.w_200 {
  width: 200px;
}

.w_170 {
  width: 170px;
}

.w_120 {
  width: 120px;
}

.w_110 {
  width: 110px;
}

.w_100 {
  width: 100px;
}

.w_90 {
  width: 90px;
}

.w_80 {
  width: 80px;
}

.w_70 {
  width: 70px;
}

.w_60 {
  width: 60px;
}

.w_50 {
  width: 50px;
}

#top_01 {
  background: url(../images/top/top_01.png) no-repeat;
  background-size: cover;
  height: 300px;
  margin: 0 0 20px 0;
  color: #ffffff;
  font-size: 11px;
}
#top_01 ul {
  margin: 0;
  padding: 15px 30px 0 0;
  text-align: right;
}
#top_01 li {
  line-height: 0;
  padding: 0 0 5px 0;
}

#top_02 {
  background: url(../images/top/top_02.png) no-repeat;
  height: 420px;
  margin: 0 0 20px 0;
}

#top_02_01 {
  margin: 0 0 10px 0;
  padding: 60px 20px 0 30px;
}

#top_02_02 {
  padding: 0 20px 0 30px;
}

#top_03 {
  margin: 0 0 0 0;
}

#top_03_top {
  background: url(../images/top/top_03_01.png) no-repeat;
  background-position: top center;
  width: 600px;
}

#top_03_middle {
  background: url(../images/top/top_03_02.png) repeat-y;
  width: 600px;
}

#top_03_middle_in {
  margin: 60px 0 20px 0;
  padding: 0 30px 20px 30px;
}
#top_03_middle_in table {
  border-collapse: collapse;
  width: 100%;
}
#top_03_middle_in td {
  background: url(../images/top/top_line.gif) repeat-x;
  background-position: bottom;
  padding: 5px 20px 5px 0;
}
#top_03_middle_in th {
  background: url(../images/top/top_line.gif) repeat-x;
  background-position: bottom;
  font-weight: normal;
  padding: 5px 0 5px 0;
}

#top_03_bottom {
  background: url(../images/top/top_03_03.png) no-repeat;
  background-position: bottom center;
  width: 600px;
}

#whatsNew p {
  border-bottom: 1px dotted #999999;
  margin: 10px 20px 0;
}

#course div {
  margin: 0 0 20px 0;
}
#course table {
  border: 1px solid #cccccc;
  font-size: 12px;
  width: 100%;
}
#course th {
  background-color: #81b8ff;
  border: 1px solid #cccccc;
  color: #ffffff;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
}
#course td {
  border: 1px solid #cccccc;
  padding: 20px 10px 20px 10px;
  vertical-align: middle;
}

#main h5 span.courseJapanese {
  background-color: #cccc00;
  color: #111111;
}
#main h5 span.courseCulture {
  background-color: #339999;
}
#main h5.recruitmentMidashi {
  border-bottom: 2px solid #999999;
  font-weight: bold;
  margin: 12px 0 6px;
  padding: 4px 0;
}

#scholarshipTaisaku {
  background: rgba(187, 171, 137, 0.3);
}
#scholarshipTaisaku h4 span {
  background-color: #339900;
  font-size: 12px;
}

.sendai_in {
  padding: 40px 230px 0 28px;
}

#sendai_01 {
  margin: 0 0 20px 0;
}

#sendai_02 {
  background: url(../images/sendai/sendai_04.png) no-repeat;
  height: 320px;
}

#sendai_03 {
  background: url(../images/sendai/sendai_05.png) no-repeat;
  height: 260px;
}

#sendai_04 {
  background: url(../images/sendai/sendai_06.png) no-repeat;
  height: 270px;
}

#sendai_05 {
  background: url(../images/sendai/sendai_07.png) no-repeat;
  height: 245px;
}

.schoollife_in {
  padding: 30px 250px 0 30px;
}

#schoollife_01 {
  margin: 0 0 20px 0;
}

#schoollife_02 {
  background: url(../images/schoollife/schoollife_04.png) no-repeat;
  height: 160px;
}

#schoollife_03 {
  background: url(../images/schoollife/schoollife_05.png) no-repeat;
  height: 155px;
}

#schoollife_04 {
  background: url(../images/schoollife/schoollife_06.png) no-repeat;
  height: 160px;
}

#schoollife_05 {
  background: url(../images/schoollife/schoollife_07.png) no-repeat;
  height: 181px;
}

#schoollife_06 {
  background: url(../images/schoollife/schoollife_09.png) no-repeat;
  height: 650px;
}
#schoollife_06 table {
  width: 100%;
}
#schoollife_06 th {
  background: url(../images/schoollife/schoollife_line.gif) repeat-x;
  background-position: bottom;
  color: #ffffff;
  font-size: 12px;
  padding: 20px 0 10px 2px;
}
#schoollife_06 td {
  background: url(../images/schoollife/schoollife_line.gif) repeat-x;
  background-position: bottom;
  padding: 23px 0 5px 5px;
}

#schoollife_06_in {
  padding: 0 15px 0 20px;
}

.list_01 {
  background: url(../images/schoollife/schoollife_list.gif) no-repeat;
  color: #ffffff;
  padding: 10px 10px 10px 2px;
}

dl#schoollifeSchedule {
  overflow: hidden;
}
dl#schoollifeSchedule dt {
  background-color: #5f4ca5;
  border-top: 1px dotted #999999;
  clear: both;
  color: #ffffff;
  float: left;
  margin: 0 6px 6px 0;
  padding: 2px;
  text-align: right;
  width: 3em;
}
dl#schoollifeSchedule dd {
  border-top: 1px dotted #999999;
  margin-left: 3.5em;
  padding: 2px;
}

.recruitment_in {
  padding: 0 20px 0 20px;
}

#recruitment_01 {
  margin: 0 0 20px 0;
}

#recruitment_02 {
  background: url(../images/recruitment/recruitment_04.png) no-repeat;
  height: 333px;
}

#recruitment_02_01 {
  margin: 0 0 10px 0;
}

#recruitment_02_02 {
  margin: 0 0 10px 0;
}
#recruitment_02_02 table {
  border: 1px solid #cccccc;
  width: 100%;
}
#recruitment_02_02 th {
  background-color: #81b8ff;
  border: 1px solid #cccccc;
  color: #ffffff;
  font-weight: normal;
  padding: 5px 0 5px 0;
  text-align: center;
}
#recruitment_02_02 td {
  border: 1px solid #cccccc;
  padding: 5px 0 5px 30px;
}

#recruitment_03 {
  background: url(../images/recruitment/recruitment_06.png) no-repeat;
  height: 220px;
}

#recruitment_04 {
  background: url(../images/recruitment/recruitment_08.png) no-repeat;
  height: 540px;
}
#recruitment_04 ul {
  padding: 0 0 10px 0;
}

#recruitment_05 {
  background: url(../images/recruitment/recruitment_10.png) no-repeat;
  height: 395px;
}
#recruitment_05 table {
  margin: 0 0 20px 0;
}
#recruitment_05 th {
  padding: 0 20px 0 0;
}

#recruitment_06 {
  background: url(../images/recruitment/recruitment_12.png) no-repeat;
  height: 285px;
}
#recruitment_06 table {
  margin: 20px 0 0 0;
}
#recruitment_06 th {
  padding: 0 40px 20px 0;
  text-align: right;
}
#recruitment_06 td {
  padding: 0 0 20px 0;
}

#recruitment .section h4 {
  background-color: #5f4ca5;
  color: #ffffff;
  margin: 20px 0 6px;
  padding: 2px 4px;
}
#recruitment .section dl {
  margin-bottom: 12px;
  overflow: hidden;
}
#recruitment .section dt {
  float: left;
  width: 2em;
}
#recruitment .section dd {
  margin-left: 2em;
}

.contact_in {
  padding: 0 20px 0 20px;
}

.contact_bottom {
  background: url(../images/contact/contact_bottom.png) no-repeat;
  background-position: bottom center;
  padding: 0 0 30px 0;
}

#contact_01 {
  margin: 0 0 20px 0;
}

#contact_02 {
  background: url(../images/contact/contact_04.png) no-repeat;
  margin: 0 0 20px 0;
}

#contact_02_01 {
  margin: 40px 0 0 0;
  width: 540px;
}

#contact_03 {
  background: url(../images/contact/contact_06.png) no-repeat;
  height: 910px;
}
#contact_03 p {
  padding: 0 0 10px 0;
}

#contact_03_01 {
  margin: 20px auto 20px 20px;
}

#contact_03_02 {
  margin: 20px auto 0 20px;
}

.hiss {
  color: #ff0000;
  font-size: 11px;
}

.err {
  color: red;
  font-size: 11px;
}

.table-form {
  width: 100%;
  border-spacing: 5px;
  margin: 0;
}
.table-form th {
  border: 3px solid #ffffff;
  background-color: #e8e3e3;
  padding: 10px;
  font-weight: normal;
  vertical-align: middle;
  color: #666666;
}
.table-form th span {
  font-size: 11px;
  margin-left: 5px;
}
.table-form td {
  font-size: 11px;
  padding: 10px;
  vertical-align: middle;
  width: 310px;
}

.align-c {
  text-align: center;
}

.input-s {
  font-size: 11px;
  width: 200px;
}

.input-m {
  font-size: 11px;
  width: 200px;
}

.input-l {
  font-size: 11px;
  width: 280px;
}

.point {
  color: #333333;
  font-size: 11px;
}

.inp4 {
  font-size: 11px;
  width: 80px;
}

.inp2 {
  font-size: 11px;
  width: 40px;
}

.submit_btn {
  border: 0;
  color: #ff0000;
  margin: 20px 0 0 0;
  text-align: center;
}

.blog {
  margin: 0 0 20px 0;
}

.blog_title {
  background: url(../images/blog/blog_01.png) no-repeat;
  background-position: top center;
  color: #297dc7;
  font-weight: bold;
  height: 60px;
}

.title_in {
  padding: 13px 0 0 30px;
}

.blog_middle {
  background: url(../images/blog/blog_02.png) repeat-y;
}

.blog_contents {
  padding: 0 0 15px 0;
}

.blog_in {
  padding: 0 20px 0 20px;
}

.blog_navi {
  background: url(../images/top/top_line.gif) repeat-x;
  background-position: top;
  padding: 5px 0 0 0;
  text-align: right;
}

.blog_bottom {
  background: url(../images/blog/blog_03.png) no-repeat;
  background-position: bottom center;
  padding: 0 0 20px 0;
}

#blog_side {
  width: 231px;
}

.blog_menu {
  background: url(../images/blog/blog_middle.png) repeat-y;
}

.blog_menu_in {
  padding: 0 10px 0 10px;
}
.blog_menu_in li {
  background: url(../images/blog/blog_list.gif) no-repeat;
  background-position: left center;
  padding: 4px 0 4px 20px;
}

.blog_line {
  background: url(../images/blog/blog_line.gif) no-repeat;
  background-position: bottom;
}

.blog_menu_bottom {
  background: url(../images/blog/blog_bottom.png) no-repeat;
  height: 13px;
  margin: 0 0 20px 0;
}

#link_01 {
  margin: 0 0 20px 0;
}

#link ul {
  list-style-type: circle;
}

#sitemap_01 {
  margin: 0 0 20px 0;
}

#sitemap ul {
  list-style: circle;
}
#sitemap li {
  padding: 0 0 10px 0;
}

#chinese_top01 {
  margin: 0 0 20px 0;
}

#chinese_top02 {
  margin: 0 0 20px 0;
}
#chinese_top02 li {
  padding: 0 0 10px 0;
}

.schedule-table tr:nth-of-type(even) {
  background: rgba(187, 171, 137, 0.3);
}

body {
  *font: x-small;
  *font-size: small;
  color: #333333;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 0.8em;
  line-height: 1.6;
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  padding: 0;
  text-align: center;
}

div {
  font-size: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  text-align: left;
}

span {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

dl {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

dt {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

dd {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

ul {
  font-size: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

ol {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

li {
  font-size: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 0;
  height: 0;
  margin: 0;
  padding: 0;
  text-indent: -9999px;
  visibility: hidden;
  width: 0;
}

h3 {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

h5 {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

h6 {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

p {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

th {
  font-size: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: top;
}

td {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

form {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

fieldset {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

input {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

textarea {
  font-size: 100%;
  margin: 0;
  padding: 0;
}

img {
  border-style: none;
}

a {
  text-decoration: underline;
  outline: none;
}
a:hover {
  color: #3a75af;
  text-decoration: underline;
}
a:link {
  color: #A89169;
}
a:visited {
  color: #A89169;
}

table {
  border-collapse: collapse;
}

/*td{text-align:left;vertical-align:top;}*/
h1 {
  float: left;
  font-size: 0;
  height: 0;
  text-indent: -9999px;
  visibility: hidden;
  width: 0;
}

#header_back {
  background: url(../images/main/header_back.jpg) repeat-x top center/contain;
  clear: both;
  height: 102px;
  margin: 0 0 20px 0;
}

#header {
  background: url(../images/main/header.jpg) no-repeat top center/contain;
  height: 102px;
  margin: 0 auto 0 auto;
  width: 850px;
}

#title {
  width: 325px;
  clear: both;
  float: left;
}
#title a {
  display: block;
  height: 75px;
  text-decoration: none;
  text-indent: -9999px;
}

#top_menu {
  width: 380px;
  float: right;
  margin: 78px 0 0 0;
}
#top_menu li {
  display: block;
  float: left;
  width: 95px;
}
#top_menu a {
  background: url(../images/main/top_menu.png) no-repeat;
  display: block;
  height: 23px;
  text-indent: -9999px;
}

#top_menu01 a {
  background-position: 0 0;
}

#top_menu02 a {
  background-position: -97px 0;
}

#top_menu03 a {
  background-position: -194px 0;
}

#top_menu04 a {
  background-position: -291px 0;
}

#top_menu05 a {
  background-position: -388px 0;
}

#menu {
  width: 231px;
  margin: 0 0 20px 0;
  background: #A89169;
  border-radius: 4px;
}
#menu h2 {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-indent: 0;
  visibility: visible;
  width: auto;
  color: #fff;
  font-weight: normal;
  padding: 10px 5px;
}
#menu h2 span:nth-of-type(1) {
  font-size: 1rem;
}
#menu h2 span:nth-of-type(2) {
  font-size: 0.7rem;
}
#menu ul {
  padding-bottom: 15px;
}
#menu li {
  display: block;
}
#menu li::after {
  content: "";
  display: block;
  width: 92%;
  margin: 0 auto;
  border-bottom: 1px solid rgba(187, 171, 137, 0.4);
  box-shadow: 0px 1px 0px rgba(187, 171, 137, 0.4);
}
#menu li a {
  height: 110px;
  width: 100%;
  max-width: 231px;
  box-sizing: border-box;
}
#menu li a span {
  display: inline-block;
  width: 100%;
  max-width: 221px;
  padding: 0 0.25rem;
}
#menu li a span span {
  background: white;
  color: #A89169;
  padding: 0.1125rem 0.25rem;
  font-weight: bold;
  margin: 0.1125rem -0.25rem;
}
#menu li#menu06 a {
  height: 150px;
}
#menu a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 0.73rem;
  color: #fff;
  padding: 0 5px;
}
#menu a > * {
  min-width: 0;
}
#menu a::before {
  content: "▶";
  display: inline-block;
  background: #fff;
  color: #A89169;
  border-radius: 50%;
  padding: 2px;
  margin-right: 5px;
  font-size: 10px;
  font-weight: 700;
  height: 10px;
  line-height: 10px;
}

#menu01 a {
  background-position: 0 0;
}
#menu01 a:hover {
  background-position: -231px 0;
}

#menu02 a {
  background-position: 0 -42px;
}
#menu02 a:hover {
  background-position: -231px -42px;
}

#menu03 a {
  background-position: 0 -84px;
}
#menu03 a:hover {
  background-position: -231px -84px;
}

#menu04 a {
  background-position: 0 -126px;
}
#menu04 a:hover {
  background-position: -231px -126px;
}

#menu05 a {
  background-position: 0 -168px;
}
#menu05 a:hover {
  background-position: -231px -168px;
}

#menu06 a {
  background-position: 0 -210px;
}
#menu06 a:hover {
  background-position: -231px -210px;
}

#menu07 a {
  background-position: 0 -252px;
}
#menu07 a:hover {
  background-position: -231px -252px;
}

#menu08 a {
  background-position: 0 -294px;
}
#menu08 a:hover {
  background-position: -231px -294px;
}

#container {
  clear: both;
  margin: 0 auto 40px auto;
  width: 850px;
}

#main {
  width: 600px;
  float: right;
  margin: 0 0 20px 0;
}
#main .section {
  background: url(../images/common/main_bg.png) repeat-y center top;
  margin: 10px 0 20px;
}
#main h3 {
  background: url(../images/common/main_top_bg.png) no-repeat center top;
  color: #A89169;
  font-size: 1.25em;
  padding: 12px 24px 20px;
}
#main h4 {
  padding: 6px 0;
}
#main h4 span {
  background-color: #A89169;
  border-bottom: 2px solid #dddddd;
  border-right: 2px solid #dddddd;
  color: #ffffff;
  font-size: 1.25em;
  font-weight: bold;
  padding: 4px 12px;
}
#main h5 {
  padding: 4px 0;
  margin-bottom: 6px;
}
#main h5 span {
  background-color: #999999;
  border-bottom: 2px solid #dddddd;
  border-right: 2px solid #dddddd;
  color: #ffffff;
  font-size: 1em;
  font-weight: bold;
  padding: 6px;
}
#main p {
  padding: 0 0 6px;
}
#main ul {
  padding-left: 2em;
}
#main li {
  list-style-position: outside;
  list-style-type: disc;
  padding: 0 0 6px 0;
}
#main table th {
  padding: 6px 4px;
}
#main table td {
  padding: 6px 4px;
}
#main .lineBottom {
  border-bottom: 1px dotted #999999;
  margin: 0 20px 20px;
  padding-bottom: 20px;
}
#main .lineBottomNone {
  margin: 0 20px;
}

.memo {
  color: #777777;
  font-size: 0.88em;
}

#side {
  float: left;
  width: 250px;
}

#side_bana .download_title {
  border-left: 6px solid #BBAB89;
  color: #BBAB89;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  padding-left: 6px;
}
#side_bana li {
  padding: 0 0 4px 0;
}

#info_bottom {
  clear: both;
  margin: 0 auto 0 auto;
  text-align: right;
  width: 850px;
}

#footer_back {
  background: url(../images/main/footer_back.png) repeat-x top center/contain;
  height: 180px;
}

#footer {
  width: 850px;
  height: 180px;
  margin: 0 auto 0 auto;
  background: url(../images/main/footer.png) no-repeat top center/contain;
  font-size: 0.9em;
}
#footer p {
  margin: 8px 0 0 0;
  text-align: center;
}

a {
  color: #ddd;
}
